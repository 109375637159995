import { combineReducers } from "redux";
import { postReducer } from "./postReducer";
import { categoryReducer } from "./categoryReducer";

const reducers = combineReducers({
  posts: postReducer,
  categories: categoryReducer
});

export default reducers;
