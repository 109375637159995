import React, { useEffect } from "react";
import Sidebar from "../../components/admincomp/comp/Sidebar";
import Header from "../../components/admincomp/comp/Header";
import Main from "../../components/admincomp/Main";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("email")) {
      navigate("/admin/login");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Sidebar/>
      <div className="main">
        <Header/>
        <Main/>
      </div>
    </>
  );
}
export default Dashboard;
