import React from 'react'
import Feature from './Feature'
import Contant from './Contant'
import BreadCrumb from '../common/BreadCrumb'

function Main() {
  return (
    <div>
    <BreadCrumb data="Core Feature" heading="Why Us?"/>
    <Contant/>
    <Feature/>
    </div>
  )
}

export default Main
