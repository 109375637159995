import React, { useEffect, useState } from "react";
import CardMain from "./CardMain";
import { useParams } from "react-router-dom";
import BreadCrumb from "./BreadCrumb";
import { Helmet } from "react-helmet";

function Hero() {
  const [postDetails, setPostDetails] = useState(null);
  const params = useParams();
  const metaTitle = `${params.categoryname} | Codes Field`;
  const metaDescription = `Explore posts about ${params.categoryname} on Codes Field.`;

  useEffect(() => {
    const fetchPostDetails = () => {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_URL}user/post/getByCategory?category=${params.categoryname.replace(/-/g, ' ')}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setPostDetails(data);
          } else {
            console.log("Error fetching post details");
          }
        })
        .catch((error) => console.log("error", error));
    };

    fetchPostDetails();
  }, [params.categoryname]);

  if (!postDetails) {
    return (
      <div className="loader-main">
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        <svg class="pl" width="240" height="240" viewBox="0 0 240 240">
          <circle
            class="pl__ring pl__ring--a"
            cx="120"
            cy="120"
            r="105"
            fill="none"
            stroke="#000"
            stroke-width="20"
            stroke-dasharray="0 660"
            stroke-dashoffset="-330"
            stroke-linecap="round"
          ></circle>
          <circle
            class="pl__ring pl__ring--b"
            cx="120"
            cy="120"
            r="35"
            fill="none"
            stroke="#000"
            stroke-width="20"
            stroke-dasharray="0 220"
            stroke-dashoffset="-110"
            stroke-linecap="round"
          ></circle>
          <circle
            class="pl__ring pl__ring--c"
            cx="85"
            cy="120"
            r="70"
            fill="none"
            stroke="#000"
            stroke-width="20"
            stroke-dasharray="0 440"
            stroke-linecap="round"
          ></circle>
          <circle
            class="pl__ring pl__ring--d"
            cx="155"
            cy="120"
            r="70"
            fill="none"
            stroke="#000"
            stroke-width="20"
            stroke-dasharray="0 440"
            stroke-linecap="round"
          ></circle>
        </svg>
      </div>
    );
  }
  return (
    <>
      <BreadCrumb data={params.categoryname} />

      <div className="nav-tab-wrapper tabs pt-10 section-padding-bottom">
        <div className="container">
          <div className="items-center mb-14">
            <div className="flex items-center justify-between rounded-md bg-white py-[10px] pr-[10px] pl-6 shadow-e1">
              <div className="flex-1">
                <input
                  type="text"
                  placeholder="Search Here"
                  className="w-full border-none focus:ring-0"
                />
              </div>
              <div>
                <button className="grid place-items-center">
                  <img src="../assets/images/svg/search.svg" alt="search" />
                </button>
              </div>
            </div>
          </div>
          <div id="tabs-content">
            <div id="tab1" className="tab-content">
              <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
                {postDetails?.data?.map((post, index) => (
                  <CardMain key={index} data={post} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
