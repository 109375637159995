import React from 'react'
import AddCategory from './comp/AddCategory'
import Table from './comp/Table'

function Category() {
  return (
    <div>
      <AddCategory/>
      <Table/>
    </div>
  )
}

export default Category
