import React from "react";
import { Link } from "react-router-dom";

function BreadCrumb(props) {
  return (
    <div className="breadcrumbs section-padding bg-[url('../images/all-img/bred.png')] bg-cover bg-center bg-no-repeat">
      <div className="container text-center">
        <h2>{props.heading}</h2>
        <nav>
          <ol className="flex items-center justify-center space-x-3">
            <li className="breadcrumb-item">
              <Link to={'/'}>Home </Link>
            </li>
            <li className="breadcrumb-item">-</li>
            <li className="breadcrumb-item">{props.data} </li>
          </ol>
        </nav>
      </div>
    </div>
  );
}

export default BreadCrumb;
