import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchAllCategory } from "../../redux/actions/categoryAction";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Footer() {
  const dispatch = useDispatch();
  const { data, error } = useSelector((state) => state.categories);
  const [credentials, setCredentials] = useState({
    email: "",
  });

  useEffect(() => {
    dispatch(fetchAllCategory());
  }, [dispatch]);
  const navigate = useNavigate();
  if (error) return navigate("/");
  if (!data) return <div>No data available.</div>;

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ email: credentials.email });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_URL}user/newsletter/addRecord`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            toast("Subscribed Successfully");
            setCredentials({
              email: "",
            });
          } else {
            toast(data.message);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <footer className="bg-black bg-[url('../images/all-img/footer-bg-1.png')] bg-cover bg-center bg-no-repeat ">
      <div className="section-padding container">
        <div className="grid grid-cols-1 gap-7 md:grid-cols-2 lg:grid-cols-3">
          <div className="single-footer">
            <div className="lg:max-w-[270px]">
              <Link to="/" className="mb-10 block">
                <img width={250} src="assets/images/logo/footer-logo.png" alt="" />
              </Link>
              <p className="text-white">
                Unlock endless possibilities with our curated collection of code
                snippets. Elevate your projects effortlessly. Welcome to a world
                of seamless coding experiences.
              </p>
            </div>
          </div>
          <div className="single-footer">
            <div className="flex space-x-[80px]">
              <div className="flex-1 lg:flex-none">
                <h4 className="mb-8 text-2xl font-bold text-white">Links</h4>
                <ul className="list-item space-y-5">
                  <li className="text-white">
                    <Link to="/">Home</Link>
                  </li>
                  {data?.data?.map((category) => (
                    <li className="text-white">
                      <Link to={"/"}>{category.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex-1 lg:flex-none">
                <h4 className="mb-8 text-2xl font-bold text-white">Our Website</h4>
                <ul className="list-item space-y-5">
                  <li className="text-white">
                    <Link to="/aboutus">About us</Link>
                  </li>
                  <li className="text-white">
                    <Link to="/contactus">Contact us</Link>
                  </li>
                  <li className="text-white">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li className="text-white">
                    <Link to="/dmca">DMCA</Link>
                  </li>
                  <li className="text-white">
                    <Link to="/disclaimer">Disclaimer</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="single-footer">
            <h4 className="mb-8 text-2xl font-bold text-white">Newsletter</h4>
            <div className="mb-8 text-white">Join Over To Stay Update</div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4 flex items-center rounded-md bg-white py-[10px] pr-[10px] pl-6 shadow-e1">
                <div className="flex-none">
                  <span className=" ">
                    <img src="assets/images/icon/mail.svg" alt="" />
                  </span>
                </div>
                <div className="flex-1">
                  <input
                    className="border-none focus:ring-0"
                    type="email"
                    name="email"
                    required
                    placeholder="Your Email"
                    value={credentials.email}
                    onChange={onChange}
                  />
                </div>
              </div>
              <button className="btn btn-primary block w-full text-center">
                Subscribe Now
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="container border-t border-white border-opacity-[0.1] py-8 text-center text-white">
        &copy; Copyright 2024 | CodesField | All Rights Reserved
      </div>
      <ToastContainer />
    </footer>
  );
}

export default Footer;
