import React, { useEffect } from 'react'
import Header from "../components/common/Header";
import MobileHeader from '../components/common/MobileHeader';
import Footer from '../components/common/Footer';
import Main from '../components/privacyPolicy/Main';

function Privacypolicy() {

    useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header/>
      <MobileHeader/>
      <Main/>
      <Footer/>
    </div>
  )
}

export default Privacypolicy
