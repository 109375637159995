import React from 'react';

const Pagination = ({ totalPages, currentPage, paginate }) => {
  const goToPage = (pageNumber) => {
    paginate(pageNumber);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const goToFirstPage = () => {
    paginate(1);
  };

  const goToLastPage = () => {
    paginate(totalPages);
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersDisplayed = 4; 

    if (totalPages <= maxPageNumbersDisplayed) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersDisplayed / 2));
      let endPage = Math.min(totalPages, startPage + maxPageNumbersDisplayed - 1);

      if (endPage - startPage < maxPageNumbersDisplayed) {
        startPage = Math.max(1, endPage - maxPageNumbersDisplayed + 1);
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };

  return (
    <div className="pagination mt-14">
      <ul className="flex justify-center space-x-3">
        {/* First Button */}
        <li className="inline-flex">
          <button onClick={goToFirstPage} disabled={currentPage === 1} className="flex w-12 h-12 items-center justify-center bg-[#ECECEC] rounded font-semibold">
            First
          </button>
        </li>

        {/* Previous Button */}
        <li className="inline-flex">
          <button onClick={goToPrevPage} disabled={currentPage === 1} className="flex w-12 h-12 items-center justify-center bg-[#ECECEC] rounded font-semibold">
            <iconify-icon icon="heroicons:chevron-double-left-20-solid" className="text-2xl"></iconify-icon>
          </button>
        </li>

        {/* Page Numbers */}
        {generatePageNumbers().map((number) => (
          <li key={number} className="inline-flex">
            <button
              onClick={() => goToPage(number)}
              className={`flex w-12 h-12 items-center justify-center ${currentPage === number ? 'bg-primary text-white' : 'bg-[#ECECEC]'} rounded font-semibold`}
            >
              {number}
            </button>
          </li>
        ))}

        {/* Next Button */}
        <li className="inline-flex">
          <button onClick={goToNextPage} disabled={currentPage === totalPages} className="flex w-12 h-12 items-center justify-center bg-[#ECECEC] rounded font-semibold">
            <iconify-icon icon="heroicons:chevron-double-right-20-solid" className="text-2xl"></iconify-icon>
          </button>
        </li>

        {/* Last Page Button */}
        <li className="inline-flex">
          <button onClick={goToLastPage} disabled={currentPage === totalPages} className="flex w-12 h-12 items-center justify-center bg-[#ECECEC] rounded font-semibold">
            Last
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
