import { getAllCategory } from "../../apis/Category";
import { ActionTypes } from "../constants/actionTypes";

export const fetchAllCategory = () => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_ALL_CATEGORY_REQUEST });
  try {
    const data = await getAllCategory();
    dispatch({ type: ActionTypes.GET_ALL_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ActionTypes.GET_ALL_CATEGORY_ERROR, payload: error });
  }
};
