import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BreadCrumb from "./BreadCrumb";
import { Helmet } from "react-helmet";

function Detail() {
  const [activeTab, setActiveTab] = useState("tab1");
  const [postDetails, setPostDetails] = useState(null);
  const [metaTitle, setMetaTitle] = useState("Loading Post... | Codes Field");
  const [metaDescription, setMetaDescription] = useState(
    "Loading post details. Please wait."
  );
  const params = useParams();

  useEffect(() => {
    fetchPostDetails();
    // eslint-disable-next-line
  }, [params.title]);

  useEffect(() => {
    if (postDetails) {
      setMetaTitle(`${postDetails.title} | Codes Field`);
      setMetaDescription(postDetails.description.substring(0, 150) + "...");
    }
  }, [postDetails]);

  const fetchPostDetails = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("user"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_API_URL}user/post/getByTitle?title=${params.title.replace(/-/g, ' ')}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setPostDetails(data.data);
        } else {
          console.error("Error fetching post details");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleTabClick = (event, tabId) => {
    event.preventDefault();
    setActiveTab(tabId);
  };

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <BreadCrumb data={params.title.replace(/-/g, ' ').toUpperCase()} />
      <div className="nav-tab-wrapper tabs  section-padding">
        <div className="container">
          <div className="grid grid-cols-12 gap-[30px]">
            <div className="lg:col-span-12 col-span-12">
              <div className="single-course-details">
                <div className="xl:h-[470px] h-[350px] mb-10 course-main-thumb">
                  <img
                    src={postDetails?.image}
                    alt=""
                    className=" rounded-md object-fut w-full h-full block"
                  />
                </div>
                <div className=" mb-6">
                  <span className="bg-secondary py-1 px-3 text-lg font-semibold rounded text-white ">
                    {postDetails?.category?.name}
                  </span>
                </div>
                <h2>{postDetails?.title}</h2>
                <div className="author-meta mt-6 sm:flex  lg:space-x-16 sm:space-x-5 space-y-5 sm:space-y-0 items-center">
                  <div className="flex space-x-4 items-center group">
                    <div className="flex-1">
                      <span className=" text-secondary  ">
                        Upload By : <div className="text-black">Admin</div>
                      </span>
                    </div>
                  </div>
                  <div>
                    <span className=" text-secondary  ">
                      Last Update :{" "}
                      <div className=" text-black">
                        {postDetails?.createdAt}
                      </div>
                    </span>
                  </div>
                </div>
                <div className="nav-tab-wrapper mt-12">
                  <ul id="tabs-nav" className="course-tab mb-8">
                    <li className={activeTab === "tab1" ? "active" : ""}>
                      <a
                        href="#tab1"
                        onClick={(e) => handleTabClick(e, "tab1")}
                      >
                        Overview
                      </a>
                    </li>
                    <li className={activeTab === "tab2" ? "active" : ""}>
                      <a
                        href="#tab2"
                        onClick={(e) => handleTabClick(e, "tab2")}
                      >
                        Download
                      </a>
                    </li>
                  </ul>
                  <div id="tabs-content">
                    {activeTab === "tab1" && (
                      <div id="tab1">
                        <div className="tab-content wdiget">
                          {postDetails?.description}
                          <div className="mt-5">
                            Demo Link:{" "}
                            <a
                              className="text-primary underline"
                              href={postDetails?.demoLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {postDetails?.demoLink}
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeTab === "tab2" && (
                      <div id="tab2" className="tab-content wdiget text-overflow">
                        <ul>
                          {console.log(postDetails.downloadLinks)}
                          {postDetails?.downloadLinks?.map((link, index) => (
                            <div key={index} className="mb-3">
                              Link {index + 1}:{" "}
                              <a
                                className="text-primary underline"
                                href={link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                              {link}
                              </a>
                            </div>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Detail;
