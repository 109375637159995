import React from "react";
import BreadCrumb from "../common/BreadCrumb";
import Content from "./Content";

function Main() {
  return (
    <div>
      <BreadCrumb data="Disclaimer" heading="Disclaimer" />
      <Content />
    </div>
  );
}

export default Main;
