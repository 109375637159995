import React from "react";
import "../admincomp/style.css";
import TotalPosts from "./comp/dashboardcards/TotalPosts";
import TotalCategory from "./comp/dashboardcards/TotalCategory";
function Main() {
  return (
    <div>
      <div className="cardBox">
        <TotalPosts/>
        <TotalCategory/>
      </div>
    </div>
  );
}

export default Main;
