import React from "react";
import { Link } from "react-router-dom";

function MobileHeader(props) {
  const $ = require("jquery");

  const handleCloseMenu = () => {
    $(".openmobile-menu").removeClass("active-mobile-menu");
    $(".rt-mobile-menu-overlay").removeClass("active");
  };
  return (
    <div
      className="openmobile-menu fixed top-0 h-screen pt-10 pb-6 bg-white shadow-box2 w-[320px] overflow-y-auto flex flex-col
        z-[999]"
    >
      <div className="flex justify-between px-6 flex-none">
        <a href="index.html" className="brand-logo flex-none mr-10 ">
          <img src="assets/images/logo/logo.svg" alt="" />
        </a>
        <span
          className=" text-3xl text-black cursor-pointer rt-mobile-menu-close"
          onClick={handleCloseMenu}
        >
          <iconify-icon icon="fe:close"></iconify-icon>
        </span>
      </div>
      <div className="mobile-menu mt-6 flex-1 ">
        <ul className="menu-active-classNamees">
          <li className=" menu-item-has-children">
            <Link to={"/"}>Home</Link>
          </li>
          {props?.data?.map((category) => (
            <li className=" menu-item-has-children">
              <Link to={"/"}>{category.name}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default MobileHeader;
