import React, { useEffect, useState } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-dt";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PostTable() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(
          "https://apis.codesfield.com/admin/post/getAll"
        );
        const data = await response.json();
        if (data && data.data) {
          setPosts(data.data);
        }
      } catch (error) {
        toast.error("Failed to fetch posts");
        console.error("Failed to fetch posts:", error);
      }
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    // Initialize DataTable
    $(document).ready(function () {
      $("#postTable").DataTable();
    });
  }, [posts]); // Re-initialize when posts change

  const handleDelete = async (postId) => {
    const url = `https://apis.codesfield.com/admin/post/deleteRecord/`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ recordId: postId }),
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      toast("Deleted Successfully");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };

  if (!posts || posts.length === 0)
    return (
      <div>
        <div className="loader-main">
          <svg class="pl" width="240" height="240" viewBox="0 0 240 240">
            <circle
              class="pl__ring pl__ring--a"
              cx="120"
              cy="120"
              r="105"
              fill="none"
              stroke="#000"
              stroke-width="20"
              stroke-dasharray="0 660"
              stroke-dashoffset="-330"
              stroke-linecap="round"
            ></circle>
            <circle
              class="pl__ring pl__ring--b"
              cx="120"
              cy="120"
              r="35"
              fill="none"
              stroke="#000"
              stroke-width="20"
              stroke-dasharray="0 220"
              stroke-dashoffset="-110"
              stroke-linecap="round"
            ></circle>
            <circle
              class="pl__ring pl__ring--c"
              cx="85"
              cy="120"
              r="70"
              fill="none"
              stroke="#000"
              stroke-width="20"
              stroke-dasharray="0 440"
              stroke-linecap="round"
            ></circle>
            <circle
              class="pl__ring pl__ring--d"
              cx="155"
              cy="120"
              r="70"
              fill="none"
              stroke="#000"
              stroke-width="20"
              stroke-dasharray="0 440"
              stroke-linecap="round"
            ></circle>
          </svg>
        </div>
      </div>
    );

  return (
    <div className="table-container">
      <table id="postTable" className="display" style={{paddingBottom:"50px"}}>
        <thead>
          <tr>
            <th>Title</th>
            <th>Image</th>
            <th>Category</th>
            <th>Description</th>
            <th>Date</th>
            <th>View</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody >
          {posts.map((post) => (
            <tr  key={post._id}>
              <td>{post.title}</td>
              <td>
                <img
                  src={post.image}
                  alt={post.title}
                  style={{ width: "100px", height: "60px" }}
                />
              </td>
              <td>{post.category.name}</td>
              <td>{post.description}</td>
              <td>{post.createdAt}</td>
              <td>{post.views}</td>
              <td>
                <button
                  onClick={() => handleDelete(post._id)}
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer />
    </div>
  );
}

export default PostTable;
