import React from "react";

function Header() {
  const handleToggleClick = () => {
    let navigation = document.querySelector(".navigation");
    let main = document.querySelector(".main");
    navigation.classList.toggle("active");
    main.classList.toggle("active");
  };

  return (
    <div>
      <div className="topbar">
        <div className="toggle" onClick={handleToggleClick}>
          <ion-icon name="menu-outline" />
        </div>
      </div>
    </div>
  );
}

export default Header;
