import React from "react";
import { Link } from "react-router-dom";

function Content() {
  return (
    <div className="container">
      <h4 className="section-padding">
        DMCA <Link to={"/"}>Codesfield.com</Link>{" "}
      </h4>
      <p className="section-padding-bottom ">
        DMCA Policy <Link to={"/"}>Codesfield.com</Link> is in compliance with
        17 U.S.C. § 512 and the Digital Millennium Copyright Act ("DMCA").{" "}
        <br></br><br></br>It is our policy to respond to any infringement notices and
        take appropriate actions under the Digital Millennium Copyright Act
        ("DMCA") and other applicable intellectual property laws. If your
        copyrighted material has been posted on{" "}
        <Link to={"/"}>Codesfield.com</Link> or if links to your copyrighted
        material are returned through our search engine and you want this
        material removed, you must provide a written communication that details
        the information listed in the following section.<br></br><br></br> Please be
        aware that you will be liable for damages (including costs and
        attorneys' fees) if you misrepresent information listed on our site that
        is infringing on your copyrights.<br></br><br></br> We suggest that you first
        contact an attorney for legal assistance on this matter. The following
        elements must be included in your copyright infringement claim:<br></br><br></br>
        Provide evidence of the authorized person to act on behalf of the owner
        of an exclusive right that is allegedly infringed.<br></br><br></br> Provide
        sufficient contact information so that we may contact you. You must also
        include a valid email address. You must identify in sufficient detail
        the copyrighted work claimed to have been infringed and including at
        least one search term under which the material appears in{" "}
        <Link to={"/"}>Codesfield.com</Link> search results. A statement that
        the complaining party has a good faith belief that use of the material
        in the manner complained of is not authorized by the copyright owner,
        its agent, or the law.<br></br><br></br> A statement that the information in the
        notification is accurate, and under penalty of perjury, that the
        complaining party is authorized to act on behalf of the owner of an
        exclusive right that is allegedly infringed.<br></br><br></br> Must be signed by
        the authorized person to act on behalf of the owner of an exclusive
        right that is allegedly being infringed. Send the infringement notice
        via our contact page Please allow 1-3 business days for an email
        response.<br></br><br></br> Note that emailing your complaint to other parties
        such as our Internet Service Provider will not expedite your request and
        may result in a delayed response due the complaint not
        properly being filed.
      </p>
    </div>
  );
}

export default Content;
