import React, { useEffect } from 'react'
import Main from '../components/category/Main'
function Category() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Main/>
    </div>
  )
}

export default Category
