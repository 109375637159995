import React, { useEffect } from "react";
import PostTable from "../../components/admincomp/comp/PostTable";
import Sidebar from "../../components/admincomp/comp/Sidebar";
import Header from "../../components/admincomp/comp/Header";
import { useNavigate } from "react-router-dom";
function ViewPost() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("email")) {
      navigate("/admin/login");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <Sidebar />
      <div className="main">
        <Header />
        <PostTable />
      </div>
    </div>
  );
}

export default ViewPost;
