import React, { useEffect } from "react";
import Header from "../components/common/Header";
import MobileHeader from "../components/common/MobileHeader";
import Footer from "../components/common/Footer";
import Main from "../components/Aboutus/Main";
import { Helmet } from "react-helmet";

function Aboutus() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>About Us | Codes Field</title>
        <meta name="description" content="Learn more about our company and our services." />
      </Helmet>
      <Header />
      <MobileHeader />
      <Main />
      <Footer />
    </div>
  );
}

export default Aboutus;
