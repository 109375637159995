import React from "react";

function Feature() {
  return (
    <div>
      <div className="feature-area bg-[url('../images/all-img/section-bg-1.png')] bg-cover bg-no-repeat bg-center section-padding">
        <div className="container">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 pt-10">
            <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
              <div
                className="h-[72px] w-[72px] rounded-full flex flex-col items-center justify-center text-secondary bg-green-paste mb-8
                text-5xl group-hover:bg-black group-hover:bg-opacity-[0.1] group-hover:text-white transition duration-150"
              >
                <iconify-icon icon="codicon:globe"></iconify-icon>
              </div>
              <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white">
                Learn More Anywhere
              </h4>
              <div className="transition duration-150 group-hover:text-white">
                Ehnance your coding skills in various languages and frameworks.
                Access our extensive library of courses, tutorials, and
                resources anytime and anywhere using Codes Fileds
              </div>
            </div>

            <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
              <div
                className="h-[72px] w-[72px] rounded-full flex flex-col items-center justify-center text-secondary bg-green-paste mb-8
                text-5xl group-hover:bg-black group-hover:bg-opacity-[0.1] group-hover:text-white transition duration-150"
              >
                <ion-icon name="code-slash-outline"></ion-icon>
              </div>
              <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white">
                Expert Solution
              </h4>
              <div className="transition duration-150 group-hover:text-white">
                Get best solutions for coding in various programming languages
              </div>
            </div>

            <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
              <div
                className="h-[72px] w-[72px] rounded-full flex flex-col items-center justify-center text-secondary bg-green-paste mb-8
                text-5xl group-hover:bg-black group-hover:bg-opacity-[0.1] group-hover:text-white transition duration-150"
              >
                <iconify-icon icon="fluent:handshake-32-regular"></iconify-icon>
              </div>
              <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white">
                24/7 Strong Support
              </h4>
              <div className="transition duration-150 group-hover:text-white">
                Countact our support team anytime you need help. They are here
                to assist you 24 hours
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feature;
