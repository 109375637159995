import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function TotalPosts() {
    const { data, loading, error } = useSelector((state) => state.posts);
    if (loading)
    return (
      <div className="loader-main">
        <svg class="pl" width="240" height="240" viewBox="0 0 240 240">
          <circle
            class="pl__ring pl__ring--a"
            cx="120"
            cy="120"
            r="105"
            fill="none"
            stroke="#000"
            stroke-width="20"
            stroke-dasharray="0 660"
            stroke-dashoffset="-330"
            stroke-linecap="round"
          ></circle>
          <circle
            class="pl__ring pl__ring--b"
            cx="120"
            cy="120"
            r="35"
            fill="none"
            stroke="#000"
            stroke-width="20"
            stroke-dasharray="0 220"
            stroke-dashoffset="-110"
            stroke-linecap="round"
          ></circle>
          <circle
            class="pl__ring pl__ring--c"
            cx="85"
            cy="120"
            r="70"
            fill="none"
            stroke="#000"
            stroke-width="20"
            stroke-dasharray="0 440"
            stroke-linecap="round"
          ></circle>
          <circle
            class="pl__ring pl__ring--d"
            cx="155"
            cy="120"
            r="70"
            fill="none"
            stroke="#000"
            stroke-width="20"
            stroke-dasharray="0 440"
            stroke-linecap="round"
          ></circle>
        </svg>
      </div>
    );
  if (error) return <></>;
  if (!data || data.status === "error") return <div>No data available.</div>;
  return (
    <div>
      <div className="card">
          <Link to={'/admin/viewpost'}>
            <div className="numbers">{data.count}</div>
            <div className="cardName">Total Posts</div>
          </Link>
          <div className="iconBx">
            <ion-icon name="document-outline" />
          </div>
        </div>
    </div>
  )
}

export default TotalPosts
