import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCategory } from "../../../redux/actions/categoryAction";

function Table() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.categories);

  useEffect(() => {
    dispatch(fetchAllCategory());
  }, [dispatch]);
  if (!data) return <div>No data available.</div>;
  return (
    <div>
        <div className="details2">
          <div className="recentOrders2">
            <div className="cardHeader2">
              <h2>Categories</h2>
            </div>
            <table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>
              {data?.data?.map((category) => (
                <tr>
                  <td>{category.name}</td>
                  <td>
                    <span className="status inProgress">Active</span>
                  </td>
                </tr>
   ))}
              </tbody>
            </table>
          </div>
        </div>
    </div>
  )
}

export default Table
