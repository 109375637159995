const getAllCategory = async () => {
    const url = `${process.env.REACT_APP_API_URL}user/category/getAll`;
    const options = { method: "GET" };
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
    }
  };
  
  export { getAllCategory };
  