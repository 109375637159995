import React, { useEffect } from "react";
import Main from "../components/course-details/Main";
function CoursesDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Main />
    </div>
  );
}

export default CoursesDetails;
