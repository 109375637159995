import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactForm() {
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(credentials );

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_URL}user/support/addRecord`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            toast("Message Sent Successfully");
            setCredentials({
              name: "",
              email: "",
              subject: "",
              message: ""
            });
          } else {
            toast(data.message);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="nav-tab-wrapper tabs  section-padding">
        <div className="container">
          <div className=" grid grid-cols-12 gap-[30px]">
            <div className="xl:col-span-12 lg:col-span-12 col-span-12">
              <form onSubmit={handleSubmit}>
                <div className="bg-white shadow-box7 p-8 rounded-md">
                  <div className="md:grid-cols-2 grid grid-cols-1 gap-[30px] mt-6 ">
                    <div>
                      <input
                        type="text"
                        className="from-control"
                        placeholder="Name*"
                        name="name"
                        value={credentials.name}
                        onChange={onChange}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="email"
                        className="from-control"
                        placeholder="Email*"
                        name="email"
                        value={credentials.email}
                        onChange={onChange}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        className="from-control"
                        placeholder="Subject"
                        name="subject"
                        value={credentials.subject}
                        onChange={onChange}
                        required
                      />
                    </div>
                    <div className="md:col-span-2 col-span-1">
                      <textarea
                        className="from-control"
                        placeholder="Your Message*"
                        name="message"
                        value={credentials.message}
                        onChange={onChange}
                        rows="5"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary mt-5">
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ContactForm;
