import  {React, useEffect } from 'react'
import Header from "../components/common/Header";
import MobileHeader from "../components/common/MobileHeader";
import Footer from "../components/common/Footer";
import Main from '../components/Contactus/Main';
import { Helmet } from 'react-helmet';
function Contactus(){
  useEffect(() => {
  window.scrollTo(0, 0);
}, []);
  return (
    <div>
      <Helmet>
        <title>Contact Us | Codes Field</title>
        <meta name="description" content="Contact us to get in touch with our team." />
      </Helmet>
      <Header />
      <MobileHeader />
      <Main />
      <Footer />
    </div>
  )
}

export default Contactus
