import React from "react";
import { Link } from "react-router-dom";
function ErrorPage() {
  return (
    <>
      <div class=" min-h-screen bg-[url('../images/all-img/404-bg.png')] bg-cover bg-no-repeat bg-center">
        <div class="max-w-[570px] mx-auto flex flex-col items-center text-center justify-center min-h-screen px-4">
          <img src="assets/images/all-img/404.svg" alt="" class="mb-14" />
          <h2 class=" mb-6">Page not found</h2>
          <div class="mb-10">
            The Page You Are Searching May Be Moved Or Removed
          </div>
          <Link to="/" class="btn btn-primary">
            Back To Home
          </Link>
        </div>
      </div>
    </>
  );
}

export default ErrorPage;
