import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Detail from "./Detail";

function Main() {
  return (
    <div>
      <Header />
      <Detail/>
      <Footer />
    </div>
  );
}

export default Main;
