import React, { useEffect } from "react";
import Sidebar from "../../components/admincomp/comp/Sidebar";
import Header from "../../components/admincomp/comp/Header";
import { useNavigate } from "react-router-dom";
import NewsletterTable from "../../components/admincomp/comp/NewsletterTable";
function Newsletter() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("email")) {
      navigate("/admin/login");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Sidebar/>
      <div className="main">
        <Header/>
        <NewsletterTable/>
      </div>
    </>
  );
}
export default Newsletter;

