import React from "react";
import BreadCrumb from "../common/BreadCrumb";
import Content from "./Content";

function Main() {
  return (
    <div>
        <BreadCrumb data="DMCA" heading="DMCA"/>
        <Content/>
    </div>
  );
}

export default Main;
