import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import MobileHeader from "./MobileHeader";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCategory } from "../../redux/actions/categoryAction";

function Header() {
  const $ = require("jquery");

  const handleMobileNav = () => {
    $(".openmobile-menu").toggleClass("active-mobile-menu");
    $(".rt-mobile-menu-overlay").addClass("active");
  };

  const dispatch = useDispatch();
  const { data, error } = useSelector((state) => state.categories);

  useEffect(() => {
    dispatch(fetchAllCategory());
  }, [dispatch]);
  const navigate = useNavigate();
  if (error) return navigate('/');
  if (!data) return <div>No data available.</div>;

  return (
    <>
      <header className="site-header  header-normal top-0 bg-white z-[9] rt-sticky">
        <div className="main-header py-8">
          <div className="container">
            <div className=" flex items-center justify-between flex-wrap">
              <Link
                to={"/"}
                className="brand-logo flex-none lg:mr-10 md:w-auto max-w-[120px] "
              >
                <img width={180} src="../assets/images/logo/logo.png" alt="Logo" />
              </Link>
              <div className="flex items-center flex-1">
                <div className="flex-1 main-menu  lg:relative xl:mr-[74px] mr-6">
                  <ul className="menu-active-classNamees">
                    <li className="">
                      <Link to={"/"}>Home</Link>
                    </li>
                    {data?.data?.map((category) => (                 
                      <li>
                        <Link to={`/category/${category.name.replace(/ /g, '-').toLowerCase()}`}>{category.name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="flex-none flex space-x-[18px]">
                  <div className=" block   lg:hidden">
                    <button
                      type="button"
                      className=" text-3xl md:w-[56px] h-10 w-10 md:h-[56px] rounded bg-[#F8F8F8] flex flex-col items-center justify-center
                                                menu-click"
                      onClick={handleMobileNav}
                    >
                      <iconify-icon
                        icon="cil:hamburger-menu"
                        rotate="180deg"
                      ></iconify-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:hidden block mt-4">
              <div className="border border-gray rounded-md  h-[46px] modal-search">
                <input
                  type="text"
                  className=" block w-full rounded-md  h-full border-none ring-0 focus:outline-none  focus:ring-0"
                  placeholder="Search.."
                />
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileHeader data={data?.data} />
    </>
  );
}

export default Header;
