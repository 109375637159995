import React from 'react'
import Addpost from '../Addpost'

function Add() {
  return (
    <>
      <Addpost/>
    </>
  )
}

export default Add
