const getAllPosts = async (page) => {
  const url = `${process.env.REACT_APP_API_URL}user/post/getAll?page=${page}`;
  const options = { method: "GET" };

  try {
    const response = await fetch(url, options);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};

export { getAllPosts };