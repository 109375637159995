import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Courses from "./pages/Courses";
import CoursesDetails from "./pages/CoursesDetails";
import ErrorPage from "./pages/ErrorPage";
import Dashboard from "./pages/adminPages/Dashboard";
import Categories from "./pages/adminPages/Categories";
import Login from "./pages/adminPages/Login";
import Posts from "./pages/adminPages/Posts";
import Category from "./pages/Category";
import ViewPost from "./pages/adminPages/ViewPost";
import Newsletter from "./pages/adminPages/Newsletter";
import Aboutus from "./pages/Aboutus";
import Contactus from "./pages/Contactus";
import Support from "./pages/adminPages/Support";
import Privacypolicy from "./pages/Privacypolicy";
import Dmca from "./pages/Dmca";
import Disclaimer from "./pages/Disclaimer";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Courses />} />
        <Route path="/script/:title" element={<CoursesDetails />} />
        <Route path="/admin/dashboard" element={<Dashboard/>} />
        <Route path="/admin/category" element={<Categories/>} />
        <Route path="/aboutus" element={<Aboutus/>} />
        <Route path="/privacy-policy" element={<Privacypolicy/>} />
        <Route path="/dmca" element={<Dmca/>} />
        <Route path="/disclaimer" element={<Disclaimer/>} />
        <Route path="/contactus" element={<Contactus/>} />
        <Route path="/admin/post" element={<Posts/>} />
        <Route path="/admin/login" element={<Login/>} />
        <Route path="/category/:categoryname" element={<Category/>} />
        <Route path="/admin/viewpost" element={<ViewPost/>} />
        <Route path="/admin/newsletter" element={<Newsletter/>} />
        <Route path="/admin/support" element={<Support/>} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
