import React, { useState, useEffect } from "react";
import CardMain from "./CardMain";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllPosts } from "../../redux/actions/postsAction";
import BreadCrumbs from "./BreadCrumbs";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Pagination from "../common/Pagination";

function Hero() {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.posts);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const metaTitle = `Explore Top Coding Insights | Codes Field`;
  const metaDescription = `Dive into the latest coding trends, tutorials, and tech discussions. Join the Codes Field community to explore, learn, and share your coding journey!`;
  const postsPerPage = 9;
  const totalPages = data ? Math.ceil(data.count / postsPerPage) : 0;


  useEffect(() => {
    dispatch(fetchAllPosts(currentPage, postsPerPage));
  }, [dispatch, currentPage, postsPerPage]);

  useEffect(() => {
    if (searchTerm) {
      const filteredSuggestions = data?.data.filter((post) =>
        post.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  }, [searchTerm, data]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion.title);
    setShowSuggestions(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (loading)
    return (
      <div className="loader-main">
        <svg class="pl" width="240" height="240" viewBox="0 0 240 240">
          <circle
            class="pl__ring pl__ring--a"
            cx="120"
            cy="120"
            r="105"
            fill="none"
            stroke="#000"
            stroke-width="20"
            stroke-dasharray="0 660"
            stroke-dashoffset="-330"
            stroke-linecap="round"
          ></circle>
          <circle
            class="pl__ring pl__ring--b"
            cx="120"
            cy="120"
            r="35"
            fill="none"
            stroke="#000"
            stroke-width="20"
            stroke-dasharray="0 220"
            stroke-dashoffset="-110"
            stroke-linecap="round"
          ></circle>
          <circle
            class="pl__ring pl__ring--c"
            cx="85"
            cy="120"
            r="70"
            fill="none"
            stroke="#000"
            stroke-width="20"
            stroke-dasharray="0 440"
            stroke-linecap="round"
          ></circle>
          <circle
            class="pl__ring pl__ring--d"
            cx="155"
            cy="120"
            r="70"
            fill="none"
            stroke="#000"
            stroke-width="20"
            stroke-dasharray="0 440"
            stroke-linecap="round"
          ></circle>
        </svg>
      </div>
    );
  if (error) return <></>;
  if (!data || data.status === "error") return <div>No data available.</div>;

  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <BreadCrumbs />
      <div className="nav-tab-wrapper tabs pt-10 section-padding-bottom">
        <div className="container">
          <div className="items-center mb-14">
            <div className="flex items-center justify-between rounded-md bg-white py-[10px] pr-[10px] pl-6 shadow-e1">
              <div className="flex-1 relative">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="w-full border-none focus:ring-0"
                  placeholder="Type to search..."
                />
                {showSuggestions && suggestions.length > 0 && (
                  <div className="suggestions">
                    {suggestions.map((suggestion, index) => (
                      <Link
                      to={`/script/${suggestion.title
                        .replace(/ /g, "-")
                        .toLowerCase()}`}
                      className="suggestion-title"
                    >
                      <div
                        key={index}
                        className="suggestion-item"
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        <img
                          src={suggestion.image}
                          alt={suggestion.title}
                          className="suggestion-image"
                        />
                        <div className="suggestion-info">
                          
                            <h5>
                              <strong>{suggestion.title}</strong>
                            </h5>
                          
                        </div>
                      </div>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
              <div>
                <button className="grid place-items-center">
                  <img src="assets/images/svg/search.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
          <div id="tabs-content">
            <div id="tab1" className="tab-content">
              <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
                {data?.data.map((post, index) => (
                  <CardMain data={post} key={index} />
                ))}
              </div>
            </div>
            <Pagination totalPages={totalPages} currentPage={currentPage} paginate={handlePageChange} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
