import React from "react";

function Contant() {
  return (
    <div>
      <div className=" section-padding bg-[url('../images/all-img/section-bg-16.png')] bg-cover  bg-no-repeat">
        <div className="container">
          <div className="grid grid-cols-12 lg:gap-10 gap-6 ">
            <div className="xl:col-span-7 lg:col-span-6 col-span-12">
              <img
              style={{width:'100%'}}
                src="assets/images/all-img/about-img.jpg"
                alt="About Img"
                draggable="false"
              />
            </div>
            <div className="xl:col-span-5 lg:col-span-6 col-span-12 flex flex-col justify-center ">
              <h4 className="primary-text">About Us</h4>
              <div>
                At Codes Field, we are a team of passionate developers
                committed to revolutionizing the way individuals approach
                coding. Our mission is to provide a platform where developers of
                all levels can find invaluable code snippets to enhance their
                projects effortlessly. With a focus on simplicity,
                accessibility, and quality, we strive to empower our users to
                unlock their full creative potential in the world of coding.
                Join us on this journey as we continue to innovate and inspire
                the coding community.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contant;
