import React, { useEffect, useState } from "react";

function NewsletterTable() {
  const [data, setData] = useState(null);
  const url = `${process.env.REACT_APP_API_URL}user/newsletter/getAll`;
  const options = { method: "GET" };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url, options);
        const data = await response.json();
        if (data.status === "success") {
          setData(data.data);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);
  if (!data)
    return (
      <div className="loader-main">
        <svg class="pl" width="240" height="240" viewBox="0 0 240 240">
          <circle
            class="pl__ring pl__ring--a"
            cx="120"
            cy="120"
            r="105"
            fill="none"
            stroke="#000"
            stroke-width="20"
            stroke-dasharray="0 660"
            stroke-dashoffset="-330"
            stroke-linecap="round"
          ></circle>
          <circle
            class="pl__ring pl__ring--b"
            cx="120"
            cy="120"
            r="35"
            fill="none"
            stroke="#000"
            stroke-width="20"
            stroke-dasharray="0 220"
            stroke-dashoffset="-110"
            stroke-linecap="round"
          ></circle>
          <circle
            class="pl__ring pl__ring--c"
            cx="85"
            cy="120"
            r="70"
            fill="none"
            stroke="#000"
            stroke-width="20"
            stroke-dasharray="0 440"
            stroke-linecap="round"
          ></circle>
          <circle
            class="pl__ring pl__ring--d"
            cx="155"
            cy="120"
            r="70"
            fill="none"
            stroke="#000"
            stroke-width="20"
            stroke-dasharray="0 440"
            stroke-linecap="round"
          ></circle>
        </svg>
      </div>
    );

  return (
    <div>
      <div className="details2">
        <div className="recentOrders2">
          <div className="cardHeader2">
            <h2>Subscribers</h2>
          </div>
          <table>
            <thead>
              <tr>
                <td>Email</td>
                <td>Date</td>
                <td>Status</td>
              </tr>
            </thead>
            <tbody>
              {data.map((subscriber) => (
                <tr key={subscriber._id}>
                  <td>{subscriber.email}</td>
                  <td>{new Date(subscriber.createdAt).toLocaleDateString()}</td>
                  <td>
                    <span className="status inProgress">Subscribed</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default NewsletterTable;
