import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Sidebar() {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("email");
     navigate("/admin/login");
     window.location.reload()
     
  };
  return (
    <div className="navigation ">
      <ul>
        <li>
          <img
            src="./assets/imgs/icon2.png"
            alt=""
            style={{ backgroundColor: "", borderRadius: 50, marginTop: 10 }}
          />
        </li>
        <li>
          <Link to="/admin/dashboard">
            <span className="icon">
              <ion-icon name="home-outline" />
            </span>
            <span className="title">Dashboard</span>
          </Link>
        </li>
        <li>
          <Link to="/admin/category">
            <span className="icon">
              <ion-icon name="grid-outline"></ion-icon>
            </span>
            <span className="title">Categories</span>
          </Link>
        </li>
        <li>
          <Link to="/admin/post">
            <span className="icon">
              <ion-icon name="add-outline"></ion-icon>
            </span>
            <span className="title">Create Post</span>
          </Link>
        </li>
        <li>
          <Link to={'/admin/viewpost'}>
            <span className="icon">
              <ion-icon name="images-outline"></ion-icon>
            </span>
            <span className="title">Show Post</span>
          </Link>
        </li>
        <li>
          <Link to={'/admin/newsletter'}>
            <span className="icon">
              <ion-icon name="newspaper-outline"></ion-icon>
            </span>
            <span className="title">News Letter</span>
          </Link>
        </li>
        <li>
          <Link to={'/admin/support'}>
            <span className="icon">
              <ion-icon name="document-text-outline"></ion-icon>
            </span>
            <span className="title">Support</span>
          </Link>
        </li>
        <li>
          <Link>
            <span className="icon">
              <ion-icon name="log-out-outline" />
            </span>
            <button  onClick={handleLogout} className="title">
              Sign Out
            </button>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
