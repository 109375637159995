import React from "react";
import { Link } from "react-router-dom";

function Content() {
  return (
    <div className="container">
  
     <h4 className="section-padding">
        Policy <Link to={"/"}>Codesfield.com</Link>{" "}
      </h4>
      <div className="section-padding-bottom">
      <strong>Cookies & 3rd Party Advertisements</strong> <br></br>
      <br></br>
      We use cookies to make our site work and for analytics and content
      optimization purposes. By using it you agree to the use of cookies for
      these purposes. The following is an outline of{" "}
      <Link to={"/"}>Codesfield.com</Link> Privacy Policy. <br></br>
      <br></br>
      <strong>General</strong> <br></br>
      <br></br>
      At <Link to={"/"}>Codesfield.com</Link>, we prioritize meeting the
      security and privacy standards you expect. You should be aware of the
      following safety and privacy issues when you browse our website. What
      information is collected by <Link to={"/"}>Codesfield.com</Link>? Standard
      use of the Site In general, you can browse the Site without telling us who
      you are or revealing any personal information. The only information we
      gather during general navigation on the Site is the information intended
      for the server logs: IP (Internet Protocol) address, domain name, browser
      type, operating system, and information such as the site from which you
      are connected, pages visited, and the dates and times of those visits.
      <br></br>
      <br></br>
      <strong>Collection of Personal Information</strong> <br></br>
      <br></br>
      If you subscribe to a newsletter, log on, request information, send any
      comments, or if you join a discussion group or electronic mailing list,
      you will need to provide some personal information: name and e-mail
      address, for example. Such information is collected only with your
      permission and is stored in different databases and mailing lists
      belonging to <Link to={"/"}>Codesfield.com</Link>. The registration of
      personal information leads to the submission of a cookie* on certain parts
      of <Link to={"/"}>Codesfield.com</Link>. By setting this cookie,{" "}
      <Link to={"/"}>Codesfield.com</Link> will remember your personal details
      at the next visit so you do not have to re-enter the same information.
      This helps us improve the quality of services that we offer. Participation
      in electronic discussion groups means that other participants in the group
      you have chosen (including those who are not Site Administrators) will be
      aware of any personal information that you choose to disclose. In some
      open discussion groups, the information is public.<br></br>
      <br></br>
      <strong>
        What does <Link to={"/"}>Codesfield.com</Link> do with the information
        it collects?
      </strong>{" "}
      <br></br>
      <br></br>
      Standard use of the Site The information collected during navigation in
      the "<Link to={"/"}>Codesfield.com</Link>" area is used to analyze trends
      and the use of the site to improve its usefulness. No personal information
      is attached there. Personal Information <br></br>
      <br></br>
      <strong>
        <Link to={"/"}>Codesfield.com</Link> may use such information to:
      </strong>
      <ul style={{ listStyleType: "square", marginLeft: "30px" }}>
        <li>
          Contact you - either in response to a request or a suggestion, or to
          send you news, documents, publications, and so on
        </li>
        <li>Confirm your entries on the site</li>
        <li>"Remember" your online profile and preferences</li>
        <li>Perform statistical analysis</li>
      </ul>
      <br></br>
      <br></br>
      <strong>
        What will happen if I do not want to provide personal information?
      </strong>{" "}
      <br></br>
      <br></br>
      Providing personal information on the Site is optional. If you do not want
      to deliver information of this kind, you can always surf the Site, but you
      will not be able to access the entire Service and its Information. Removal
      or modification of information You can delete or modify your personal
      information at any time by returning to the page where you registered them
      or by contacting the coordinator of the page. If the page in question does
      not provide any information on this subject, you can contact
      privacy(at)codesfield(dot)com for more information. If you are not the
      issuer of personal information that concerns you and want to have it
      removed, you can send your request to privacy(at)codesfield(dot)com
      Security The personal data entrusted to the Site will not be sold or
      transferred to any third party. Any information provided to{" "}
      <Link to={"/"}>Codesfield.com</Link>
      by users of the Site is preserved with the utmost care and maximum safety.
      It will be used only in the manner described in this privacy policy or
      according to the special rules of visited sites or in a manner to which
      you have explicitly consented. <Link to={"/"}>Codesfield.com</Link> uses
      techniques and a variety of measures to ensure the safety of information
      stored in its systems and protects them from loss, misuse, unauthorized
      access, disclosure, alteration, or destruction. All our volunteers who
      have access to personal information or are associated with the processing
      of these data are obliged to respect the confidentiality of every user of
      the Site, which covers the personal information. The Site provides links
      to sites outside the "codesfield" area and could not be held responsible
      for the privacy practices or the content located on them. Modifications
      Any modification of these terms can be made at any time without prior
      publication or announcement.<br></br>
      <br></br>
      <strong>Contact us</strong> <br></br>
      <br></br>
      For any questions or inquiries about our privacy policy, you can 
      <Link to={"/contactus"}>Contact us</Link> <br></br>
      <br></br>
     </div>
    </div>
  );
}

export default Content;
