import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCategory } from "../../redux/actions/categoryAction";

function Addpost() {
  const [image, setImage] = useState({});

  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.categories);

  useEffect(() => {
    dispatch(fetchAllCategory());
  }, [dispatch]);

  const [credentials, setCredentials] = useState({
    title: "",
    description: "",
    categoryId: "",
    demoLink: "",
    downloadLinks: "",
  });

  const onChangeImage = async (e) => {
    setImage(e.target.files[0]);
  };

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };





  const downloadLinksArray = credentials.downloadLinks
      .split("\n")
      .map((link) => [link.trim()]);

      console.log(downloadLinksArray);

    const newArr = downloadLinksArray.map((el) => {
      return el[0];
    });

    console.log(newArr)





  const handleimage = async () => {
    const url = "https://apis.codesfield.com/admin/utils/uploadImage";
    const form = new FormData();
    form.append("image", image);

    const options = { method: "POST" };

    options.body = form;

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        return data.data.image;
      } else {
        toast("Internal Server Error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const imageUrl = await handleimage();
    const url = "https://apis.codesfield.com/admin/post/addRecord";

    // Split the download links by newline characters
    const downloadLinksArray = credentials.downloadLinks
      .split("\n")
      .map((link) => [link.trim()]);
    const newArr = downloadLinksArray.map((el) => {
      return el[0];
    });

    const formData = {
      title: credentials.title,
      description: credentials.description,
      category: credentials.categoryId,
      image: imageUrl,
      demoLink: credentials.demoLink,
      downloadLinks: newArr,
    };

    const urlencoded = new URLSearchParams(formData);

    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: urlencoded,
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Post added successfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(data.message || "Failed to add post");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to add post");
    }
  };

  return (
    <div>
      <div className="details1">
        <div className="recentOrders1">
          <div className="cardheader1">
            <h3 style={{ fontWeight: "light", color: "rgb(255 126 132 )" }}>
              ADD POST
            </h3>
            <div className="xl:col-span-7 lg:col-span-6 col-span-12">
              <form onSubmit={handleSubmit}>
                <div className=" md:grid-cols-2 grid grid-cols-1 gap-[30px] mt-6 ">
                  <div>
                    <input
                      type="text"
                      className=" from-control"
                      placeholder="Title"
                      name="title"
                      onChange={onChange}
                      required
                    />
                  </div>
                  <div>
                    <select
                      className="form-control form-control-lg border-none form-sel "
                      onChange={onChange}
                      name="categoryId"
                      required
                    >
                      <option selected disabled>
                        Open this select menu
                      </option>
                      {data?.data?.map((category) => (
                        <option value={category.name} key={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <input
                      type="file"
                      className=" from-control"
                      name="image"
                      onChange={onChangeImage}
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      className=" from-control"
                      placeholder="Demo Link"
                      name="demoLink"
                      onChange={onChange}
                      required
                    />
                  </div>
                  <div>
                    <textarea
                      type="text"
                      cols={3}
                      rows={3}
                      className=" from-control"
                      placeholder="Download Links (separate by space)"
                      name="downloadLinks"
                      onChange={onChange}
                    />
                  </div>
                  <div>
                    <textarea
                      type="text"
                      cols={3}
                      rows={3}
                      className=" from-control"
                      placeholder="Description"
                      name="description"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-primary mt-[30px]">
                  Add Post
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Addpost;
