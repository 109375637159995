import React from "react";
import { Link } from "react-router-dom";

function BreadCrumbs() {
  return (
    <div className=" breadcrumbs section-padding bg-[url('../images/all-img/bred.png')] bg-cover bg-center bg-no-repeat">
      <div className="container text-center">
        <h2>Welcome to Codes Field</h2>
        <nav>
          <ol className="flex items-center justify-center space-x-3">
            <li className="breadcrumb-item">
              <Link to="/">Home </Link>
            </li>
            <li className="breadcrumb-item">-</li>
            <li className="breadcrumb-item">Explore latest Snippets</li>
          </ol>
        </nav>
      </div>
    </div>
  );
}

export default BreadCrumbs;
