import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function Login() {  
const navigate = useNavigate();
useEffect(() => {
  if (localStorage.getItem("email")) {
    navigate("/admin/dashboard");
  }
  // eslint-disable-next-line
}, []);
const [credentials, setCredentials] = useState({
  email: "",
  password: "",
});

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //dummy data
    const staticCredentials = {
      email: "admin@gmail.com",
      password: "admin@123",
    };

    if (credentials.email.length === 0) {
      toast("Please enter Email");
      return;
    }
    if (credentials.password.length === 0) {
      toast("Please Enter Your Password");
      return;
    }
    if (credentials.email === staticCredentials.email && credentials.password === staticCredentials.password) {
      toast("Login Successful");
      localStorage.setItem("email", staticCredentials.email);
      //console.log(localStorage.getItem("email"));
      navigate('/admin/dashboard')
    } else {
      toast("Invalid Credentials");
    }
  };
  return (
    <div className="loginbg">
      <div className="loginpage">
        <div className="containerlogin">
          <div className="heading">Sign In</div>
          <form className="formlogin" onSubmit={handleSubmit}>
            <input
              placeholder="E-mail"
              id="email"
              name="email"
              type="email"
              className="input"
              required=""
              onChange={onChange}
              value={credentials.email}
            />
            <input
              placeholder="Password"
              id="password"
              name="password"
              type="password"
              className="input"
              required=""
              onChange={onChange}
              value={credentials.password}
            />
            <button type="submit" className="login-button">Sign In</button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
export default Login;
