import React from "react";
import { Link } from "react-router-dom";

function Content() {
  return (
    <div className="container">
      <h4 className="section-padding">
        Disclainmer <Link to={"/"}>Codesfield.com</Link>{" "}
      </h4>
      <div className="section-padding-bottom">
      DISCLAIMER None of the files shown here are hosted or transmitted by this
      server. <br></br><br></br> The owner of this site, Codesfield.com cannot be held responsible for
      what its users are posting. <br></br><br></br> The links and content are indexed from other
      sites on the net. <br></br><br></br> You may not use this site to distribute or download any
      material when you do not have the legal rights to do so. <br></br><br></br> If you have any
      doubts about legality of content or you have another suspicions, feel free
      to <Link to={"/contactus"}>Countact Us</Link> at <Link to={"/"}>Codesfield.com</Link> or use the "REPORT ABUSE"
      button. <br></br><br></br>Thank you!
      </div>
    </div>
  );
}

export default Content;
