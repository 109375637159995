import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Hero from "./Hero";

function Main() {
  return (
    <>
      <Header />
      <Hero />
      <Footer />
    </>
  );
}

export default Main;
