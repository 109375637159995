import React from "react";
import ContactForm from "./ContactForm";
import Breadcrumb from "../common/BreadCrumb";
function Main() {
  return (
    <div>
      <Breadcrumb data="Contact Us" heading="Contact Us"/>
      <ContactForm/>
    </div>
  );
}

export default Main;
