import { getAllPosts } from "../../apis/Posts";
import { ActionTypes } from "../constants/actionTypes";

export const fetchAllPosts = (page) => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_ALL_POST_REQUEST });
  try {
    const data = await getAllPosts(page);
    dispatch({ type: ActionTypes.GET_ALL_POST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ActionTypes.GET_ALL_POST_ERROR, payload: error });
  }
};
