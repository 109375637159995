import React, { useEffect } from 'react'
import Header from "../../components/admincomp/comp/Header";
import Sidebar from '../../components/admincomp/comp/Sidebar'
import Add from '../../components/admincomp/comp/Add'
import { useNavigate } from 'react-router-dom';
function Posts() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("email")) {
      navigate("/admin/login");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Sidebar/>
      <div className="main">
        <Header/>
        <Add/>
      </div>
    </>
  )
}

export default Posts
