import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


function TotalCategory() {
    const { data } = useSelector((state) => state.categories);
const catCount = data?.data?.length || 0;
  return (
    <div>
      <div className="card">
          <Link to={'/admin/category'}>
            <div className="numbers">{catCount}</div>
            <div className="cardName">Total Categories</div>
          </Link>
          <div className="iconBx">
            <ion-icon name="layers-outline" />
          </div>
        </div>
    </div>
  )
}

export default TotalCategory
