import React, { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddCategory() {
  
  const [credentials, setCredentials] = useState({
    name: "",
  });
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validations
    if (credentials.name.length === 0) {
      toast("Please Enter Parameters");
      return;
    }

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      var urlencoded = new URLSearchParams();
      urlencoded.append("name", credentials.name);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_URL}admin/category/addRecord`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            setCredentials({
              name: "",
            });
            toast(`Category "${response.result.name}" added successfully!`);
          } else if (response.status === "error") {
            toast(response.message);
            setCredentials({
              ...credentials,
              name: "",
            });
          } else {
            toast("Internal Server Error");
          }
        })
        .catch((error) => {
          console.log(error);
          toast("Internal Server Error");
        });
    } catch (error) {
      console.log(error);
      toast("Internal Server Error");
    }
  };
  return (
    <div>
      <div className="details1">
        <div className="recentOrders1">
          <div className="cardheader1">
            <h3 style={{ fontWeight: "light", color: "rgb(255 126 132 )" }}>
              ADD CATEGORY
            </h3>
            <div class="xl:col-span-7 lg:col-span-6 col-span-12">
              <form onSubmit={handleSubmit}>
                <div class=" md:grid-cols-2 grid grid-cols-1 gap-[30px] mt-6 ">
                  <div>
                    <input
                      type="text"
                      className=" from-control"
                      placeholder="Category Name"
                      name="name"
                      onChange={onChange}
                      required
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-primary mt-[30px]">
                  Add Category
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default AddCategory;
