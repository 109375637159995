import React from "react";
import { Link } from "react-router-dom";

function CardMain(props) {
  return (
    <Link 
      to={`/script/${props.data.title.replace(/ /g, '-').toLowerCase()}`}
      className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
      href="single-course.html"
    >
      <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
        <img
          src={props?.data?.image}
          alt=""
          className=" w-full h-full object-cover rounded-t-[8px]"
        />
        <span className="bg-secondary py-1 px-3 text-lg font-semibold rounded text-white absolute left-6 top-6">
          {props?.data?.category?.name}
        </span>
      </div>
      <div className="course-content p-8">
        <h4 className=" text-xl mb-3 font-bold">{props?.data?.title}</h4>
        <p className="mb-4 line-clamp-2">{props?.data?.description}</p>
        <div className="flex justify-between  flex-wrap space-y-1 xl:space-y-0">
          <span className=" flex items-center space-x-2 mr-3">
            <img src="assets/images/svg/clock.svg" alt="" />
            <span>{props?.data?.createdAt}</span>
          </span>
          <span className=" flex items-center space-x-2 ">
            <img src="assets/images/svg/admin.svg" alt="" />
            <span>{props?.data?.views}</span>
          </span>
        </div>
      </div>
    </Link>
  );
}

export default CardMain;
