import React, { useEffect } from 'react'
import Header from '../components/common/Header'
import MobileHeader from '../components/common/MobileHeader'
import Footer from '../components/common/Footer'
import Main from '../components/disclaimer/Main'

function Disclaimer() {
    useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header/>
      <MobileHeader/>
      <Main/>
      <Footer/>
    </div>
  )
}

export default Disclaimer
